var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.materials.length === 0
        ? _c(
            "div",
            { ref: "empty-image", staticClass: "text-center empty-item-text" },
            [
              _vm._v(
                "\n\t\t" + _vm._s(_vm.FormMSG(1, "No material Found")) + "\n\t"
              ),
            ]
          )
        : _c(
            "div",
            [
              _vm.$screen.width < 992
                ? _c("card-list-builder", {
                    attrs: {
                      items: _vm.materials,
                      fields: _vm.materialMobileFields,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "actions",
                          fn: function (data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "mr-1 btn bg-transparent border-0",
                                  attrs: { variant: "primary", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editMaterial(data.item)
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.EDIT.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn bg-transparent border-0",
                                  attrs: { variant: "danger", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteMaterial(data.item.id)
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.TRASH.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      540139802
                    ),
                  })
                : _vm._e(),
              _vm.$screen.width >= 992
                ? _c("b-table", {
                    ref: "materialTable",
                    attrs: {
                      hover: _vm.hover,
                      items: _vm.materials,
                      fields: _vm.materialFields,
                      "current-page": _vm.currentPage,
                      "per-page": _vm.perPage,
                      "head-variant": _vm.hv,
                      responsive: "sm",
                      bordered: "",
                      small: "",
                      "empty-text": _vm.FormMSG(2, "No material found"),
                      "show-empty": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(edit)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn bg-transparent border-0",
                                  attrs: { variant: "primary", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editMaterial(data.item)
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.EDIT.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(delete)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn bg-transparent border-0",
                                  attrs: { variant: "danger", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteMaterial(data.item.id)
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.TRASH.color,
                                      size: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2453395805
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
      _c("task-type-material-modal", {
        attrs: { material: _vm.material },
        on: { "task-type-material-modal:cancel": _vm.onCancelMaterialModal },
        model: {
          value: _vm.isOpenMaterialModal,
          callback: function ($$v) {
            _vm.isOpenMaterialModal = $$v
          },
          expression: "isOpenMaterialModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }