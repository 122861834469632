<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-modal header-class="header-class-modal-doc-package" ref="task-type-material-modal" v-model="isOpen" size="md" @hidden="onCancel" hide-footer>
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<div class="d-block">
				<task-type-material-form :material="material" @task-type-material:updated="materialUpdated" />
			</div>
		</b-modal>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import TaskTypeMaterialForm from '@/components/TaskType/Material/Form';

export default {
	name: 'TaskTypeMaterialModal',
	mixins: [languageMessages],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		material: {
			type: Object,
			required: true,
			default: null
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},
		okTitle() {
			return this.FormMSG(1, 'Ok');
		},
		modalTitle() {
			return this.FormMSG(2, 'Update material') + `: #${this.material.id}`;
		}
	},
	components: {
		TaskTypeMaterialForm
	},
	data() {
		return {
			erreur: {},
			warning: ''
		};
	},
	methods: {
		onCancel() {
			this.$emit('task-type-material-modal:cancel', false);
		},
		materialUpdated(payload) {
			this.$refs['task-type-material-modal'].hide();
			this.$emit('task-type-material-modal:taskTypeMaterialUpdated', payload);
		}
	}
};
</script>
