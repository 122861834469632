var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-modal",
        {
          ref: "task-type-material-modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            size: "md",
            "hide-footer": "",
          },
          on: { hidden: _vm.onCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\t" + _vm._s(_vm.modalTitle) + "\n\t\t")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c("task-type-material-form", {
                attrs: { material: _vm.material },
                on: { "task-type-material:updated": _vm.materialUpdated },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }