var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "4", lg: "4" } }, [
                        _c("h4", [_vm._v(_vm._s(_vm.rendTitle))]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { md: "8", lg: "8" } },
                        [
                          _c(
                            "b-row",
                            {
                              class: `${
                                _vm.$screen.width >= 992
                                  ? "pull-right pr-2"
                                  : "pull-right"
                              }`,
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "btn btn-warning mr-2",
                                      attrs: { to: "/tasks", tag: "b-button" },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(24, "Back to tasks")
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t "
                                      ),
                                      _c("i", {
                                        staticClass: "icon-action-undo",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn btn-primary btn-md",
                                      attrs: { variant: "success", size: "md" },
                                      on: { click: _vm.backToList },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(2, "Back to list")
                                          ) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "icon-action-undo",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card-body",
                [
                  _c(
                    "fieldset",
                    { staticClass: "scheduler-border" },
                    [
                      _c("legend", { staticClass: "scheduler-border" }, [
                        _vm._v(_vm._s(this.FormMSG(17, "Form"))),
                      ]),
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.onSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "9", lg: "9" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(3, "Name"),
                                        "label-for": "name",
                                        "label-cols": 3,
                                        horizontal: true,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          required: "",
                                          placeholder: _vm.FormMSG(
                                            4,
                                            "Name..."
                                          ),
                                        },
                                        model: {
                                          value: _vm.taskType.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.taskType, "name", $$v)
                                          },
                                          expression: "taskType.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(25, "Description"),
                                        "label-for": "description",
                                        "label-cols": 3,
                                        horizontal: true,
                                      },
                                    },
                                    [
                                      _c("b-form-textarea", {
                                        attrs: {
                                          type: "textarea",
                                          rows: "12",
                                          placeholder: _vm.FormMSG(
                                            26,
                                            "Description..."
                                          ),
                                          autocomplete: "off",
                                        },
                                        model: {
                                          value: _vm.taskType.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.taskType,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "taskType.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(7, "Duration"),
                                        "label-for": "duration",
                                        "label-cols": 3,
                                        horizontal: true,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex row" }, [
                                        _c("div", { staticClass: "col-6" }, [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                    },
                                                    [
                                                      _c("b-form-timepicker", {
                                                        attrs: {
                                                          type: "text",
                                                          required: "",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              8,
                                                              "Duration..."
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.taskType
                                                              .duration,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.taskType,
                                                              "duration",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "taskType.duration",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-2 mt-2 task-label text-success font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  22,
                                                                  "Hours/d"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "col-6" }, [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "number",
                                                          step: "1",
                                                          min: "0",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              23,
                                                              "Days..."
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.taskType
                                                              .dayDuration,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.taskType,
                                                              "dayDuration",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "taskType.dayDuration",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ml-2 mt-2 task-label text-success font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  21,
                                                                  "Days"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(9, "Priority"),
                                        "label-for": "priority",
                                        "label-cols": 3,
                                        horizontal: true,
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: { options: _vm.priorities },
                                        model: {
                                          value: _vm.taskType.priority,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.taskType,
                                              "priority",
                                              $$v
                                            )
                                          },
                                          expression: "taskType.priority",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _c("b-row", [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-3" },
                                                  [
                                                    _vm.taskType.picture !== ""
                                                      ? _c("b-img", {
                                                          attrs: {
                                                            src: _vm.getPicture(
                                                              _vm.taskType
                                                                .picture
                                                            ),
                                                            width: "32",
                                                            height: "32",
                                                          },
                                                        })
                                                      : _c("b-img", {
                                                          attrs: {
                                                            "blank-color":
                                                              "#D3DEDC",
                                                            width: "32",
                                                            height: "32",
                                                            rounded: "circle",
                                                            blank: "",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-9" },
                                                  [
                                                    !_vm.taskType.isSystem
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn-block mb-3",
                                                            attrs: {
                                                              variant:
                                                                "outline-secondary",
                                                              size: "md",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.startCapture,
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _c("i", {
                                                                staticClass:
                                                                  "icon-camera",
                                                              }),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      5,
                                                                      "Change your picture"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3", lg: "3" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "submit",
                                            variant: "primary",
                                            size: "md",
                                            block: "",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.FormMSG(13, "Save"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-card",
                            {
                              attrs: {
                                "no-body": "",
                                "header-tag": "header",
                                "footer-tag": "footer",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mr-2 text-success task-icon-card-header",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-cart-arrow-down",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-muted task-label-card-header",
                                            },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        16,
                                                        "Materials associated"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "footer",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", {
                                            attrs: { md: "9", lg: "9" },
                                          }),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3", lg: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                { staticClass: "my-0" },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "py-2 px-4 tags-assigned",
                                                      attrs: {
                                                        type: "button",
                                                        variant:
                                                          "outline-primary",
                                                        size: "md",
                                                        block: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addNewMaterial,
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              18,
                                                              "Add"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c("task-type-material-list", {
                                    ref: "task-type-material-list",
                                    on: {
                                      "task-type-material:reloadData":
                                        _vm.reloadMaterialList,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("task-type-material-modal", {
                    attrs: { material: _vm.material },
                    on: {
                      "task-type-material-modal:cancel":
                        _vm.onCancelMaterialModal,
                    },
                    model: {
                      value: _vm.isOpenMaterialModal,
                      callback: function ($$v) {
                        _vm.isOpenMaterialModal = $$v
                      },
                      expression: "isOpenMaterialModal",
                    },
                  }),
                ],
                1
              ),
              _c("NewCapture", {
                key: _vm.refreshTokenCaptureItem,
                attrs: {
                  "parent-type": "task_type",
                  "parent-id": _vm.taskType.id,
                  "auto-crop-capture": "",
                  "cam-mode-first": false,
                  "only-one-crop-mode": "",
                },
                on: { change: _vm.handleImgsTaked },
                model: {
                  value: _vm.capturePict,
                  callback: function ($$v) {
                    _vm.capturePict = $$v
                  },
                  expression: "capturePict",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }