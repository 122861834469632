<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-container fluid>
			<b-form @submit.prevent="onSubmit">
				<b-row>
					<b-col cols="12">
						<b-form-group :label="FormMSG(1, 'Name')" label-for="Name" :label-cols="3" :horizontal="true">
							<b-form-input v-model="material.name" type="text" :placeholder="FormMSG(2, 'Name or description...')" />
						</b-form-group>
						<b-form-group :label="FormMSG(3, 'Unit')" label-for="Unit" :label-cols="3" :horizontal="true">
							<b-form-select v-model="material.unit" :placeholder="FormMSG(4, 'Choose an unit...')" :options="units" @change="onSelectUnit" />
						</b-form-group>
						<b-form-group v-if="material.unit === 0" :label="FormMSG(5, 'Custom unit')" label-for="CustomUnit" :label-cols="3" :horizontal="true">
							<b-form-input v-model="material.customUnit" type="text" :placeholder="FormMSG(6, 'Your custom unit...')" />
						</b-form-group>
						<b-form-group :label="FormMSG(8, 'Unit price')" label-for="UnitPrice" :label-cols="3" :horizontal="true">
							<b-form-input v-model="material.unitPrice" type="number" step="0.01" min="0.00" max="100000000.00" @change="updateTotalPrice" />
						</b-form-group>
						<b-form-group :label="FormMSG(7, 'Quantity')" label-for="Quantity" :label-cols="3" :horizontal="true">
							<input
								v-model="material.quantity"
								v-mask="'################'"
								type="text"
								class="form-control"
								@keyup="updateTotalPrice"
								@change="updateTotalPrice"
							/>
						</b-form-group>
						<b-form-group :label="FormMSG(9, 'Total price')" label-for="TotalPrice" :label-cols="3" :horizontal="true">
							<b-form-input v-model="material.totalPrice" type="number" step="0.01" min="0.00" max="100000000.00" placeholder="0.00" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="9"> &nbsp; </b-col>
					<b-col cols="3">
						<b-form-group>
							<b-button type="submit" variant="primary" size="md" block>
								<span>{{ FormMSG(10, 'Save') }}</span>
							</b-button>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</b-container>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { updatedTaskTypeItem } from '@/cruds/tasktypes.crud';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'TaskTypeMaterialForm',
	mixins: [languageMessages, globalMixin],
	props: {
		material: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {
			filter: '',
			erreur: {},
			warning: ''
		};
	},
	computed: {
		units() {
			return this.FormMenu(1317);
		}
	},
	methods: {
		async onSubmit() {
			this.material.quantity = parseInt(this.material.quantity, 10);
			this.material.unitPrice = parseFloat(this.material.unitPrice);
			this.material.totalPrice = parseFloat(this.material.totalPrice);
			await updatedTaskTypeItem(this.material.id, this.material);
			// console.log('submitted: ', this.material);

			this.createToastForMobile(this.FormMSG(12, 'Success'), this.FormMSG(11, 'Task type material updated successfully'), '', 'success');

			this.$emit('task-type-material:updated', this.material);
		},
		onSelectUnit(payload) {
			// console.log('onSelectUnit: ', payload)
		},
		updateTotalPrice() {
			this.material.totalPrice = this.material.unitPrice * this.material.quantity;
		}
	}
};
</script>
